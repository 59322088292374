
import "./styles.css"

import logo from "../../assets/logo.png"

import practitioner from "../../assets/cp.png"
import ssa from "../../assets/ssa.png"
import dva from "../../assets/dva.png"
import sap from "../../assets/sap.png"
import ssp from "../../assets/ssp.png"




import Header from "../../components/header"

import { MdOndemandVideo } from "react-icons/md";
import { FaCode } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";


import { Link } from "react-router-dom"

export default function LandingPage(){



    function handlerSend(){
        window.location.href = "https://trycloudtreinamento.carrinho.app/one-checkout/ocmtb/12437399"
    }
    return(
        <>

        <Header />
        
        <div className="section-1">
            <div className="infos">
                <h3>Se prepare para as melhores certificações AWS.</h3>
                <p>Adquira habilidades necessárias para impulsionar sua carreira e se destacar no mercado.</p>
                <div className="price">
                    <p>De <span>R$ 39,90</span> por R$ 9,90/mês</p>
                </div>
                <Link to="https://trycloudtreinamento.carrinho.app/one-checkout/ocmtb/12437399" >
                <button>Assine agora</button>
                </Link>
                <span>Assine por apenas <strong>R$ 9,90/mês</strong> plano anual</span>
                
                    <p className="turma">Disponível dia  22/01/2024</p>
                
            </div>

            <div className="img-section-1">
                <img src={logo} alt="" />
            </div>
            
        </div>

        <div className="section-2">
            <h2 className="border-bottom">Se prepare na Try Cloud</h2>
            <div className="flex">
                <div className="cont-box">
                    <MdOndemandVideo className="icon"/>
                    <h2>Aulas</h2>
                    <p>Conteúdo em video para sua preparação</p>
                </div>
                <div className="cont-box">
                    <FaCode className="icon"/>
                    <h2>Laboratorios</h2>
                    <p>Crie banco de dados, redes e muito mais</p>
                </div>

                <div className="cont-box">
                    <FaClipboardCheck className="icon"/>
                    <h2>Simulados</h2>
                    <p>Teste seus conhecimentos e mande bem na certificação</p>
                </div>


                <FaCode />
            </div>
        </div>

        <div className="section-3">
            <h2>Nossos prepratorios</h2>
            <div className="cert-box">
                <img src={practitioner} alt="" />
                <h2>AWS Cloud Practitioner</h2>
                <p>Preparatótio para a certificação nível fundamental da aws, aqui iremos apresentar os conceitos e beneficios de cloud, introdução aos serviços basicos como EC2, RDS, VPC...</p>
                <span><FaCheck className="icon"/> Aulas preparatórias</span>
                <span><FaCheck className="icon"/> Laboratorios práticos</span>
                <span><FaCheck className="icon"/> Simulados para certificação</span>
            </div>

            <div className="cert-box">
                <img src={ssa} alt="" />
                <h2>AWS Solutions Architect Associate</h2>
                <p>Essa certificação valida habilidades técnicas na criação de arquiteturas escaláveis e resilientes, cobrindo conceitos de computação em nuvem, serviços fundamentais da AWS e boas práticas de arquitetura.</p>
                <span><FaCheck className="icon"/> Aulas preparatórias</span>
                <span><FaCheck className="icon"/> Laboratorios práticos</span>
                <span><FaCheck className="icon"/> Simulados para certificação</span>
            </div>

            <div className="cert-box">
                <img src={dva} alt="" />
                <h2>AWS Developer Associate</h2>
                <p> Projetada para profissionais que criam soluções escaláveis e seguras na nuvem, essa certificação destaca competências no uso de serviços AWS para desenvolvimento de software e operações em ambientes na nuvem.</p>
                <span><FaCheck className="icon"/> Aulas preparatórias</span>
                <span><FaCheck className="icon"/> Laboratorios práticos</span>
                <span><FaCheck className="icon"/> Simulados para certificação</span>
            </div>

            <div className="cert-box">
                <img src={sap} alt="" />
                <h2>AWS Solutions Architect Professional</h2>
                <p>Essa certificação busca habilidades avançadas na criação de arquiteturas complexas e escaláveis, focando em projetos de alto nível, migração, otimização de performance e segurança. </p>
                <span><FaCheck className="icon"/> Aulas preparatórias</span>
                <span><FaCheck className="icon"/> Laboratorios práticos</span>
                <span><FaCheck className="icon"/> Simulados para certificação</span>
            </div>

            <div className="cert-box">
                <img src={ssp} alt="" />
                <h2>AWS Security Specialty</h2>
                <p>Projetada para profissionais de segurança, essa certificação cobre tópicos como identidade e acesso, proteção de dados, conformidade e resposta a incidentes. Essa certificação destaca a expertise em garantir a segurança de ambientes complexos na nuvem.</p>
                <span><FaCheck className="icon"/> Aulas preparatórias</span>
                <span><FaCheck className="icon"/> Laboratorios práticos</span>
                <span><FaCheck className="icon"/> Simulados para certificação</span>
            </div>
        </div>

        <div className="section-4">
            <div className="ass-box">
                <span>Aproveite a promoção da Try Cloud</span>
                <span>Disponível dia 22/01/2024</span>
                <h2>De <span>R$ 39,90</span> por R$ 9,90/mês</h2>
                <p>no plano anual</p>
           
                <button onClick={handlerSend}>Assine agora</button>
            
                
               
          
            </div>
        </div>
        </>
    )
}