import "./styles.css"

import Header from "../../components/header"

import { useForm } from "react-hook-form";
import { isEmail } from "validator";

import api from "../../services/api"


import { useNavigate } from "react-router-dom"

export default function Cadastro(){

    const navigate = useNavigate()


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm();
    
      const watchPassword = watch("password");
    
      const onSubmit = (data) => {
        enviar(data);
      };

    async function enviar(data){
      const response = await api.post("/cadastro", {data})
      console.log(response.status)
      if(response.status === 200){
        navigate('/')
      }
    }

    
    return(
        <>
        <Header />
        <div className="title">
            <p>Crie sua conta para iniciar sua assinatura</p>
        </div>
        <div className="app-container">
      <div className="form-group">
        <label>Nome completo</label>
        <input
          className={errors?.name && "input-error"}
          type="text"
          placeholder="Nome completo"
          {...register("name", { required: true })}
        />
        {errors?.name?.type === "required" && (
          <p className="error-message">Name is required.</p>
        )}
      </div>

      <div className="form-group">
        <label>E-mail</label>
        <input
          className={errors?.email && "input-error"}
          type="email"
          placeholder="Seu e-mail"
          {...register("email", {
            required: true,
            validate: (value) => isEmail(value),
          })}
        />
        {errors?.email?.type === "required" && (
          <p className="error-message">Email is required.</p>
        )}

        {errors?.email?.type === "validate" && (
          <p className="error-message">Email is invalid.</p>
        )}
      </div>

      <div className="form-group">
        <label>Senha</label>
        <input
          className={errors?.password && "input-error"}
          type="password"
          placeholder="Senha"
          {...register("password", { required: true, minLength: 7 })}
        />

        {errors?.password?.type === "required" && (
          <p className="error-message">Password is required.</p>
        )}

        {errors?.password?.type === "minLength" && (
          <p className="error-message">
            Password needs to have at least 7 characters.
          </p>
        )}
      </div>

      <div className="form-group">
        <label>Confirmar Senha</label>
        <input
          className={errors?.passwordConfirmation && "input-error"}
          type="password"
          placeholder="Repita sua senha"
          {...register("passwordConfirmation", {
            required: true,
            validate: (value) => value === watchPassword,
          })}
        />
        {errors?.passwordConfirmation?.type === "required" && (
          <p className="error-message">Password confirmation is required.</p>
        )}

        {errors?.passwordConfirmation?.type === "validate" && (
          <p className="error-message">Passwords does not match.</p>
        )}
      </div>

      <div className="form-group">
        <div className="checkbox-group">
          <input
            type="checkbox"
            name="privacy-policy"
            {...register("privacyTerms", {
              validate: (value) => value === true,
            })}
          />
          <label>I agree with the privacy terms.</label>
        </div>

        {errors?.privacyTerms?.type === "validate" && (
          <p className="error-message">
            You must agree with the privacy terms.
          </p>
        )}
      </div>

      <div className="form-group">
        <button onClick={() => handleSubmit(onSubmit)()}>Criar conta</button>
      </div>
    </div>
        </>
    )
}