import "./styles.css"

import logo from "../../assets/logo-try.png"
import { IoPersonOutline } from "react-icons/io5";

import { useNavigate } from "react-router-dom"


export default function Header(){

    const navigate = useNavigate()

    function handlerSend(){
        navigate("/")
    }
    return(
        <div className="head">
            <img src={logo} onClick={handlerSend} alt="" />
            <IoPersonOutline onClick={handlerSend} className="icon"/>
        </div>
    )
}