import Header from "../../components/header"



import { FaCheck } from "react-icons/fa";

export default function SucessBoleto(){
    return(
        <>
        <Header />


        <div className="section-sucess">
           
         
                <div className="cont-box">
                    <div className="apro">
                    <FaCheck className="icon"/>
                    <h2>Aguardando pagamento do boleto.</h2>
                    </div>
                    
                    <p>Bem vindo a <span>Try Cloud !</span></p>

                    <div className="span-class">
                        <span>Assim que o pagamento for registrado enviaremos as credenciais de acesso para o e-mail cadastrado anteriormente, verifique seu e-mail e siga os passos para concluir o cadastro.</span>
                    </div>
                </div>
               


               
         
        </div>

        </>
    )
}