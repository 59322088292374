import { BrowserRouter, Route, Routes} from "react-router-dom"


import LandingPage from "./pages/LandingPage/index.js"
import Cadastro from "./pages/Cadastro"
import SucessCard from "./pages/SucessCard/index.js"
import SucessBoleto from "./pages/SucessBoleto/index.js"


export default function Rotas(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<LandingPage/>}/> 
                <Route path="/cadastro" element={<Cadastro/>}/> 
                <Route path="/sucess-creditcard"  element={<SucessCard/>}/> 
                <Route path="/sucess-boleto"  element={<SucessBoleto/>}/>         
            </Routes>
        </BrowserRouter>
    )
}